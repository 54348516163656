<template>
  <v-menu
    translate="slide-y"
    elevation="4"
    offset-y
    nudge-bottom="16px"
    left
    min-width="306px"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        rounded
        icon
        large
        :outlined="$vuetify.breakpoint.mobile ? false : true"
        :class="[$vuetify.breakpoint.mobile ? 'ml-2' : 'ml-4']"
        elevation="0"
        color="gray"
        @click="readAll"
      >
        <div class="notifications-unread" v-if="hasUnreadNotification"></div>
        <v-icon color="black">$bell</v-icon>
      </v-btn>
    </template>

    <div>
      <notifications-manager-empty v-if="!notifications.length" />
      <notifications-manager
        v-else
        :notifications="notifications"
        @remove-notification="remove"
        @loadNextPage="loadNextPage"
        @clearAll="clearAll"
      />
    </div>
  </v-menu>
</template>

<script>
import NotificationsManager from "./NotificationsManager.vue";
import NotificationsManagerEmpty from "./NotificationsManagerEmpty.vue";
import moment from "moment";

export default {
  components: {
    NotificationsManager,
    NotificationsManagerEmpty,
  },
  computed: {
    hasUnreadNotification: function () {
      for (const item of this.notifications) {
        if (!item.read_at) {
          return true;
        }
      }
      return false;
    },
    notifications: function () {
      return this.$store.state.NotificationsModule.notifications.map((item) => {
        item.status = item.type.endsWith("_failed") ? "error" : "ok";
        item.created_at_text = moment(item.created_at).fromNow();
        return item;
      });
    },
  },
  methods: {
    loadNextPage: function () {
      this.$store.dispatch("loadNextPageNotifications");
    },
    remove(item) {
      this.$store.dispatch("removeNotification", item.id);
    },
    clearAll: function () {
      this.$store.dispatch("removeAllNotifications");
    },
    readAll: function () {
      this.$store.dispatch("readAllNotifications");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  &::v-deep {
    position: relative;
    transition: color 0.24s ease, background-color 0.24s ease,
      border-color 0.24s ease;
    .v-icon svg {
      transition: color 0.24s ease;
      color: var(--v-darkicon-base) !important;
    }
    &:hover,
    &[aria-expanded="true"] {
      color: map-get($primary, base) !important;
      border-color: map-get($primary, darken1);
      .v-icon svg {
        color: map-get($primary, darken1) !important;
      }
    }
    &[aria-expanded="true"] {
      background-color: map-get($primary, lighten4);
    }
  }
  & .notifications-unread {
    position: absolute;
    right: -10px;
    top: -5px;
    background-color: map-get($primary, base);
    width: 12px;
    height: 12px;
    border-radius: 100%;
    z-index: 5;
  }
}
</style>