import Api from "@/apis/Api";

export default {
	data() {
		return {
			dnsRecordsToSet: [],
		}
	},
	methods: {
		wpAdminSsoRedirect() {
			if (this.selectedService.id === 0) {
				return;
			}

			const timeout = this.selectedService.onboarding.super_quick_redirect_delay_after_site_creation * 1000;
			setTimeout(() => {
				Api.post(
					`/instances/${this.installedInstance.id}/sso-url`,
					{user: 1}
				).then(response => {
					//window.open(response.data.data.sso_url, '_blank');
					window.location.href = response.data.data.sso_url;
				})
					.catch((error) => {
						this.$store.dispatch("addAlert", {
							success: false,
							errorMessage: Api.getErrorMessage(error),
						});
					})
					.finally(() => {
						this.installationCompleted = true;
						this.installationError = false;
					})
			}, timeout);
		},
	}
}