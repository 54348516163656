<template>
  <div
    class="instance-actions__content text-center"
    :class="
      state
        ? state === 'error'
          ? 'instance-actions__content--error'
          : 'instance-actions__content--pending'
        : ''
    "
  >
    <template v-if="!state">
      <v-btn
        color="primary"
        elevation="0"
        small
        max-width="184px"
        min-width="184px"
        :loading="manageSiteLoading"
        @click.stop.prevent="manageSite"
      >
        <span class="font-weight-bold">{{ $t('button.site.manage') }}</span>
      </v-btn>
      <v-btn
        class="mt-4 link-hover"
        color="info"
        elevation="0"
        style="background-color: var(--v-link-base);"
        small
        max-width="184px"
        min-width="184px"
        @click.stop.prevent="$emit('openWpLogin')"
      >
        <v-icon v-if="!checkingWpLogin" class="white-text" size="16">
          $wordpressalt
        </v-icon>
        <loader
          v-else
          class="mr-3 white-text"
          size="16"
          :color="'info'"
          :noMargins="true"
        />
        <span class="font-weight-bold white-text">{{ $t('button.wpAdmin') }}</span>
      </v-btn>

      <v-tooltip
        v-if="previewNotAvailable"
        transition="custom-tooltip"
        open-delay="150"
        bottom
        z-index="99"
        offset-overflow
        nudge-bottom="4px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-on="on"
            v-bind="attrs"
            class="
              preview-not-available__text
              p-4
              pt-4
              gray--text
              text--darken-2
            "
          >
            {{ $t('message.notPreview.title') }}
            <span class="primary--text">{{ $t('button.why') }}</span>
          </div>
        </template>

        <span v-if="instance.isNew()">
          {{ $t('message.notPreview.info.new') }}
        </span>
        <span v-else>
          {{ $t('message.notPreview.info.other') }}
        </span>
      </v-tooltip>
    </template>
    <template v-else-if="state === 'error'">
      <p class="font-weight-bold p2 heading--text">
        {{ $t('message.status.install.failed') }} {{ $t('button.try') }}
      </p>

      <v-btn
        small
        class="mt-4"
        color="success"
        elevation="0"
        max-width="184px"
        min-width="184px"
        @click.stop.prevent="$emit('restartInstallation')"
      >
        <v-icon color="white" size="16">$restore</v-icon>
        <span class="font-weight-bold">{{ $t('button.try') }}</span>
      </v-btn>
      <v-btn
        small
        class="mt-4"
        color="error"
        elevation="0"
        max-width="184px"
        min-width="184px"
        @click.stop.prevent="$emit('cancelInstallation')"
      >
        <span class="font-weight-bold">{{ $t('button.installation.cancel') }}</span>
      </v-btn>
    </template>
    <template v-else-if="state === 'in_progress'">
      <p class="font-weight-bold p2 heading--text">
        {{ $t('message.status.install.inProgress') }} {{ $t('message.managementNotAvailable') }}
      </p>

      <v-btn
        small
        color="error"
        elevation="0"
        max-width="184px"
        min-width="184px"
        @click.stop.prevent="$emit('cancelInstallation')"
      >
        <span class="font-weight-bold">{{ $t('button.installation.cancel') }}</span>
      </v-btn>
    </template>
  </div>
</template>

<script>
import Loader from "../Loader.vue";

export default {
  components: {
    Loader,
  },
  props: {
    instance: Object,
    checkingWpLogin: Boolean,
    state: {
      type: [String, Boolean],
      default: false,
    },
    previewNotAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      manageSiteLoading: false,
    };
  },
  methods: {
    manageSite() {
      this.manageSiteLoading = true;
      this.$nextTick(() => {
        this.$router.push({ path: `/instances/${this.instance.id}` });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  &:hover {
    .instance-actions {
      opacity: 1;
      &__content {
        transform: translateY(0px);
      }
    }
  }
  .instance-actions {
    &__content {
      width: 100%;
      transition: transform 0.3s ease-out;
      transform: translateY(16px);
      position: relative;
      z-index: 9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    // @media (max-width: 767px) {
    //   .v-btn {
    //     margin-top: 0px !important;
    //     &:not(:first-child) {
    //       margin-left: 16px;
    //     }
    //   }
    // }
  }
}

.link-hover:hover {
  background: var(--v-link-hover) !important;
  border-color: var(--v-link-hover) !important;
}

</style>
