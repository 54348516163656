<template>
  <div
    class="d-flex flex-nowrap data-iterator-filter-controls"
    :class="[
      disabled ? 'data-iterator-filter-controls--disabled' : '',
      $vuetify.breakpoint.lgAndDown && fullWidthLg ? 'w-100' : '',
      $vuetify.breakpoint.mdAndDown && fullWidthMd ? 'w-100' : '',
    ]"
  >
    <div v-if="filter" class="data-iterator-filter-controls__item">
      <data-iterator-filter
        :labels="labels"
        :appliedLabels="filterLabels"
        @update:appliedLabels="applyFilterLabels"
      />
    </div>

    <div v-if="sortBy && keys" class="data-iterator-filter-controls__item data-iterator-filter-controls__item--sort-instances">
      <data-iterator-sort-by
        :sortBy="sortBy"
        :keys="keys"
        :sortDesc="sortDesc"
        v-on="$listeners"
      />
    </div>
    <div v-if="showSearchTerm" class="data-iterator-filter-controls__item">
      <data-iterator-search
        :fullWidthSearch="fullWidthSearch"
        :searchTermData.sync="searchTermData"
      />
    </div>
  </div>
</template>

<script>
import DataIteratorFilter from "./DataIteratorFilter.vue";
import DataIteratorSortBy from "./DataIteratorSortBy.vue";
import DataIteratorSearch from "./DataIteratorSearch.vue";

export default {
  components: {
    DataIteratorFilter,
    DataIteratorSortBy,
    DataIteratorSearch,
  },
  props: {
    disabled: Boolean,
    sortBy: {
      type: [Object, Boolean],
      default: false,
    },
    keys: {
      type: [Array, Boolean],
      default: () => false,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    searchTerm: {
      type: String,
      default: () => "",
    },
    showSearchTerm: {
      type: Boolean,
      default: () => true,
    },
    filter: {
      type: Boolean,
    },
    filterLabels: {
      type: Array,
      default: () => [],
    },
    fullWidthSearch: {
      type: Boolean,
      default: false,
    },
    fullWidthLg: {
      type: Boolean,
      default: true,
    },
    fullWidthMd: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      timeout: null,
    };
  },
  computed: {
    labels: function () {
      return this.$store.state.LabelsModule.availableLabels;
    },
    sortByData: {
      get: function () {
        return this.sortBy;
      },
      set: function (newValue) {
        this.$emit("update:sortBy", newValue);
      },
    },
    sortDescData: {
      get: function () {
        return this.sortDesc;
      },
      set: function (newValue) {
        this.$emit("update:sortDesc", newValue);
      },
    },
    searchTermData: {
      get: function () {
        return this.searchTerm;
      },
      set: function (newValue) {
        this.$emit("update:searchTerm", newValue);
      },
    },
  },
  methods: {
    applyFilterLabels: function (data) {
      this.$emit("update:filterLabels", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.data-iterator-filter-controls {
  position: relative;
  transition: opacity 0.24s ease;
  &__item {
    display: flex;
    &:not(:last-child) {
      margin-right: 16px;
    }
    &:last-child:not(:first-child) {
      margin-left: auto;
    }
  }
  &--disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
}

.v-input::v-deep .v-input__control .v-input__slot .v-input__prepend-inner {
  margin-top: 12px;
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .data-iterator-filter-controls {
    &__item {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

.v-application--is-rtl{
    .data-iterator-filter-controls {
        &__item {
            &:not(:last-child) {
            margin-right: 0px;
            margin-left: 16px;
            }
        }
    }
}
</style>
