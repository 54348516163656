<template>
  <v-dialog v-model="opened">
    <v-card class="basic-modal" elevation="4" style="width: 560px">
      <v-card-title class="d-flex justify-space-between">
        <h5 class="heading--text d-flex align-center">
          <v-icon size="24" class="mr-4" color="primary">$wrench </v-icon>
          <span>{{ $t("heading.instance.staging.title") }}</span>
        </h5>
        <v-btn v-if="!submitting" icon x-small @click="opened = false">
          <v-icon size="24">$close</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <div class="d-flex flex-column pb-4">
          <label class="base--text mb-2">
            {{ $t("form.label.domain") }}
            <form-help-icon :text="$t('tooltip.create_staging.domain')" />
          </label>
          <v-text-field
            v-if="allowAnyDomain"
            outlined
            v-model="postData.domain"
            class="v-input--md"
            hide-details="auto"
            validate-on-blur
            dense
            :rules="domainFieldRules"
          ></v-text-field>
          <text-select-input
            v-else
            :suffix="suffix"
            :text="subdomain"
            :select="parentDomain"
            :availableSelectOptions="selectOptions"
            readonly
            :label="$t('form.label.domain')"
            :rules="domainFieldRules"
            :value="postData.domain"
            @update:value="postData.domain = $event"
          />
        </div>

        <div class="last-child d-flex flex-column align-start w-100">
          <template v-if="loadingUsage">
            <span
              >{{
                $t("heading.instance.modal.createStaging.calculatingUsage")
              }}...</span
            >
            <v-skeleton-loader type="text" class="w-100" />
          </template>
          <template v-else>
            <ul>
              <li>
                {{ $t("heading.instance.modal.createStaging.wordpressUsage") }}:
                <strong>{{ wpDiskUsage }}</strong>
              </li>
              <li>
                {{
                  $t("heading.instance.modal.createStaging.uploadsDirUsage")
                }}:
                <strong>{{ uploadsDirUsage }}</strong>
              </li>
              <li>
                {{
                  $t("heading.instance.modal.createStaging.availableDiskSpace")
                }}:
                <strong> {{ availableDiskSpace }} </strong>
              </li>
            </ul>
            <label
              class="base--text checkbox-label d-flex align-center p-2 mt-2"
            >
              <checkbox
                outlined
                class="mr-1"
                v-model="postData.skip_uploads_dir"
                @change="postData.skip_uploads_dir = $event"
                :checked="forceSkipUploadsDir"
                :disabled="forceSkipUploadsDir"
                :label="$t('form.label.skip_uploads_dir')"
                dense
              ></checkbox>
              <form-help-icon
                :text="$t('tooltip.create_staging.skip_uploads_dir')"
              />
            </label>
          </template>
        </div>
      </v-card-text>

      <v-card-actions class="d-flex flex-wrap pb-4 pt-8">
        <div class="w-100 pb-2">
          <v-btn
            class="primary"
            large
            block
            elevation="0"
            @click="submit()"
            :loading="submitting"
            :disabled="loadingUsage"
          >
            <span class="p-2 font-weight-600">{{
              $t("button.createStaging")
            }}</span>
          </v-btn>
        </div>
        <div class="w-100 pb-2">
          <v-btn
            large
            block
            elevation="0"
            @click="opened = false"
            :disabled="submitting"
          >
            <span
              class="p-2"
              :class="'font-weight-light gray--text text--darken-1'"
              >{{ $t("button.cancel") }}
            </span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/apis/Api";
import Helper from "@/apis/Helper";
import FormHelpIcon from "../FormHelpIcon.vue";
import Checkbox from "../buttons/Checkbox.vue";
import TextSelectInput from "../inputs/TextSelectInput.vue";
import psl from "psl";
import DomainValidationMixin from "../../mixins/DomainValidationMixin";

export default {
  components: { FormHelpIcon, Checkbox, TextSelectInput },
  mixins: [DomainValidationMixin],
  data() {
    return {
      instance: {},
      retryId: null,
      opened: false,
      loadingUsage: false,
      allowAnyDomain: false,
      subdomain: "staging",
      parentDomain: "example.com",
      suffix: ".",
      usage: {
        account_usage: null,
        account_limit: null,
        wordpress_dir_usage: null,
        uploads_dir_usage: null,
        wordpress_db_usage: null,
      },
      postData: {
        domain: "",
        skip_uploads_dir: false,
      },
      submitting: false,
    };
  },
  computed: {
    wpDiskUsage() {
      return Helper.formatBytes(
        this.usage.wordpress_dir_usage + this.usage.wordpress_db_usage
      );
    },
    uploadsDirUsage() {
      return Helper.formatBytes(this.usage.uploads_dir_usage);
    },
    availableDiskSpace() {
      if (!this.usage.account_limit) {
        return this.$t(
          "heading.instance.modal.createStaging.unlimited"
        );
      }
      if (this.usage.account_limit - this.usage.account_usage < 0) {
        return "0 B";
      }
      return Helper.formatBytes(
        this.usage.account_limit - this.usage.account_usage
      );
    },
    forceSkipUploadsDir() {
      return (
        this.usage.account_limit &&
        this.usage.wordpress_dir_usage + this.usage.wordpress_db_usage >
          this.usage.account_limit - this.usage.account_usage
      );
    },
    domainFieldRules() {
      return [
        (v) =>
          !!v ||
          this.$t("form.validation.required", {
            field: this.$t("form.label.domain"),
          }),
        (v) =>
          this.isValidDomain(v) ||
          this.$t("form.validation.valid", {
            field: this.$t("form.label.domainName"),
          }),
      ];
    },
    selectOptions() {
      return [{ label: this.parentDomain, value: this.parentDomain }];
    },
  },
  methods: {
    open(instance, retryId = null) {
      this.instance = instance;
      this.retryId = retryId;
      this.prepareDomainField();
      this.loadUsage();
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    prepareDomainField() {
      const settings = this.instance.plan_settings.domain_for_staging;
      this.parentDomain = this.instance.domain;
      let domain = this.instance.domain;
      if (settings.type === "fixed_subdomain") {
        this.parentDomain = settings.fixed_domain;
        domain = settings.fixed_domain;
      } else if (settings.type === "any_domain") {
        this.allowAnyDomain = true;
        domain = "staging." + domain;
      }
      if (this.instance.service_has_dns_cloudflare) {
        const parsedDomain = psl.parse(domain);
        if (parsedDomain.subdomain) {
          this.suffix = "-";
          domain = `${parsedDomain.subdomain.replace(/\./g, "-")}.${
            parsedDomain.domain
          }`;
        }
      }
      this.postData.domain = this.subdomain + this.suffix + this.parentDomain;
    },
    loadUsage() {
      this.loadingUsage = true;
      this.usage = {
        account_usage: null,
        account_limit: null,
        wordpress_dir_usage: null,
        uploads_dir_usage: null,
        wordpress_db_usage: null,
      };
      Api.get(`/instances/${this.instance.id}/staging/pre-check-disk-usage`)
        .then((response) => {
          this.usage = response.data.data;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.loadingUsage = false;
        });
    },
    submit() {
      this.submitting = true;
      if (this.forceSkipUploadsDir) {
        this.postData.skip_uploads_dir = true;
      }
      let method = "post";
      let endpoint = `/instances/${this.instance.id}/staging`;
      let emit = "staging-created";
      if (this.retryId) {
        method = "put";
        endpoint = `/instances/${this.instance.id}/retry-staging/${this.retryId}`;
        emit = "staging-install-retry";
      }

      Api[method](endpoint, this.postData)
        .then((response) => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t(
              "notification.instance.modal.staging.create.success"
            ),
          });
          this.close();
          this.$parent.$emit(emit, response.data.data);
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style src="./BasicModal.scss" lang="scss" scoped/>
