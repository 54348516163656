<template>
  <div
    class="text-select-input"
    :class="[
      hasError ? 'text-select-input--error' : '',
      isSelected ? 'text-select-input--focus' : '',
    ]"
  >
    <v-text-field
      outlined
      :placeholder="placeholder"
      v-model="textComputed"
      class="v-input--md text-select-input__text-input"
      hide-details="auto"
      validate-on-blur
      :error-messages="errorsBag"
      dense
      @focus="setFocus(true)"
      @blur="setFocus(false)"
      ref="text"
    ></v-text-field>

    <div class="text-select-input__suffix" v-if="suffix">
      <span class="p-2">
        {{ suffix }}
      </span>
    </div>

    <v-select
      class="text-select-input__select-input"
      outlined
      v-model="selectComputed"
      :items="availableSelectOptions"
      item-text="label"
      hide-details="auto"
      item-value="value"
      dense
      @focus="setFocus(true)"
      @blur="setFocus(false)"
      ref="select"
      :readonly="readonly"
      :append-icon="!readonly ? '$dropdown' : ''"
      :menu-props="{
        offsetY: true,
      }"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
    },
    select: {
      type: [String, Object],
      default: () => ({
        label: "",
        value: "",
      }),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    availableSelectOptions: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    validate: function () {
      var errorsBag = [];

      for (var i = 0; i < this.rules.length; i++) {
        var rule = this.rules[i];
        var result = rule(this.value);
        if (result !== true) {
          errorsBag.push(result);
        }
      }

      this.errorsBag = errorsBag;

      if (errorsBag.length) {
        this.hasError = true;
      } else {
        this.hasError = false;
      }

      return errorsBag;
    },
    setFocus: function (value) {
      this.hasError = !(
        this.$refs.text.validate() && this.$refs.select.validate()
      );
      this.isSelected = value;
    },
  },
  data: function () {
    return {
      isSelected: false,
      hasError: false,
      errorsBag: [],
    };
  },
  computed: {
    value: function () {
      return this.textComputed + this.suffix + this.selectComputed;
    },
    textComputed: {
      get: function () {
        return this.text;
      },
      set: function (newValue) {
        this.$emit("update:text", newValue);
        this.$emit(
          "update:value",
          newValue + this.suffix + this.selectComputed
        );
      },
    },
    selectComputed: {
      get: function () {
        if (!this.select) {
          return this.availableSelectOptions[0];
        }
        return this.select;
      },
      set: function (newValue) {
        this.$emit("update:select", newValue);
        this.$emit("update:value", this.textComputed + this.suffix + newValue);
      },
    },
  },
  mounted: function () {
    // this.$parent.register(this);
  },
};
</script>

<style lang="scss" scoped>
.text-select-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;

  .v-input::v-deep {
    &.v-text-flied {
      margin-bottom: 16px;
    }
    fieldset {
      border: none;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 46px;
    width: 100%;
    border-radius: 3px;
    pointer-events: none;
    transition: outline 0.24s ease, border 0.24s ease, width 0.24s ease,
      margin 0.24s ease;
  }
  &::before {
    border: 1px solid var(--v-gray-lighten2);
    z-index: 11;
  }
  &::after {
    border: 2px solid transparent;
    z-index: 10;
  }
  &:hover {
    &::before {
      border: 1px solid map-get($gray, darken1);
    }
  }
  &--error {
    .v-input::v-deep {
      .v-input__icon--append {
        svg {
          color: map-get($error, base);
        }
      }
    }
    &::before {
      border-color: transparent !important;
    }
    &::after {
      border: 2px solid map-get($error, base);
    }
  }
  &--focus {
    .v-input::v-deep {
      .v-input__icon--append {
        svg {
          color: map-get($primary, base);
        }
      }
    }
    &::before {
      border-color: transparent !important;
    }
    &::after {
      border: 2px solid map-get($primary, base);
    }
  }
  &__text-input {
    display: flex;
  }
  &__suffix {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    margin: 0 -4px;
    z-index: 10;
    background: var(--v-gray-lighten5);
    span {
      color: map-get($gray, darken1);
    }
  }
}
</style>
