<template>
  <div class="table-item item--shares" :class="main ? 'group-start' : ''">
    <div class="item">
      <shares-label
        v-if="item.getUserPrivileges('general.manage_shared_access')"
        :instance="item" showAssignText
      />
      <div v-else>-</div>
    </div>
  </div>
</template>
  
  <script>
import SharesLabel from "../../labels/SharesLabel.vue";
export default {
  components: { SharesLabel },
  props: {
    item: {
      type: Object,
    },
    main: {
      type: Boolean,
      default: false,
    },
  },
};
</script>