import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import Backend from 'i18next-http-backend'
import axios from "axios";
import store from "@/store/store";

const apiUrl = (() => {
	return process.env.VUE_APP_API_URL
		|| `//${window.location.hostname}${window.location.port
			? `:${window.location.port}`
			: ``}/api`;
})();

i18next
	.use(Backend)
	.init({
		fallbackLng: 'en',
		lng: 'en',
		backend: {
			loadPath: `${apiUrl}/{{lng}}/translation.json`
		},
		interpolation: {
			escapeValue: false,
			format: function (value, format) {
				if (format === 'capitaliseFirstLetter') return value.replace(/\b\w/g, function (match) {
					return match.toUpperCase();
				});
				return value;
			}
		},
	});


const changeLanguage = async (lang) => {
	await ensureTranslationsLoaded(lang);

	if (!i18next.hasResourceBundle(lang, 'translation')) {
		const additionalTranslations = await getTranslations(lang);
		if (additionalTranslations) {
			i18next.addResourceBundle(lang, 'translation', additionalTranslations, true, true);
		}
	}

	await i18next.changeLanguage(lang);
	setDirection(lang);
}

const getTranslations = async (lang) => {
	if (!lang) return null;

	try {
		const response = await axios.get(`${apiUrl}/${lang}/translation.json`);
		return response.data;
	} catch (error) {
		return null;
	}
}

const setDirection = (lang) => {
	const direction = i18next.dir(lang);
	store.$app.$vuetify.rtl = direction === 'rtl';
}


const ensureTranslationsLoaded = async (lang) => {
	if (!i18next.hasResourceBundle(lang, 'translation')) {
		await new Promise((resolve) => {
			i18next.loadLanguages(lang, () => {
				resolve();
			});
		});
	}
};

export {i18next, I18NextVue, changeLanguage};