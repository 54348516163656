export default {
	data() {
		return {
			domainRegex:
				/[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,63}(:[0-9]{1,5})?(\/[a-zA-Z0-9_~-]+)*$/,
		};
	},
	methods: {
		isValidDomain(v) {
			return this.domainRegex.test(v);
		},
	},
};