<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.1284 15H14.7704C13.4885 15 12.4494 16.0788 12.4494 17.4096V52.5904C12.4494 53.9212 13.4885 55 14.7704 55H56.1284C57.4103 55 58.4494 53.9212 58.4494 52.5904V17.4096C58.4494 16.0788 57.4103 15 56.1284 15Z"
      fill="var(--v-illustrations-lighten1)"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M52.1284 19H10.7704C9.48855 19 8.4494 20.0788 8.4494 21.4096V56.5904C8.4494 57.9212 9.48855 59 10.7704 59H52.1284C53.4103 59 54.4494 57.9212 54.4494 56.5904V21.4096C54.4494 20.0788 53.4103 19 52.1284 19Z"
      fill="var(--v-illustrations-lighten3)"
      stroke="var(--v-illustrations-darken1)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.5859 38.0001C24.5859 40.7188 26.1681 43.0586 28.4633 44.1728L25.1876 35.2146C24.8087 36.0614 24.5859 37.0196 24.5859 38.0001Z"
      fill="#797997"
    />
    <path
      d="M36.0845 37.6658C36.0845 36.819 35.7725 36.2397 35.5274 35.7717C35.1708 35.2146 34.8589 34.7243 34.8589 34.1672C34.8589 33.5433 35.3268 32.9416 36.0176 32.9416C36.0399 32.9416 36.0845 32.9416 36.1068 32.9416C34.8812 31.8274 33.2544 31.1366 31.4717 31.1366C29.065 31.1366 26.9703 32.3622 25.7447 34.2341C25.9006 34.2341 26.0566 34.2341 26.1903 34.2341C26.9034 34.2341 28.0176 34.145 28.0176 34.145C28.3965 34.145 28.441 34.6575 28.0622 34.7021C28.0622 34.7021 27.6834 34.7466 27.2823 34.7689L29.7781 42.2118L31.2711 37.7104L30.2015 34.7912C29.8227 34.7912 29.4884 34.7243 29.4884 34.7243C29.1096 34.7243 29.1541 34.145 29.533 34.1672C29.533 34.1672 30.6695 34.2564 31.338 34.2564C32.0511 34.2564 33.1653 34.1672 33.1653 34.1672C33.5441 34.1672 33.5887 34.6798 33.2098 34.7243C33.2098 34.7243 32.831 34.7689 32.4299 34.7912L34.9034 42.1672L35.5942 39.872C35.8839 38.9138 36.1068 38.2452 36.1068 37.6436L36.0845 37.6658Z"
      fill="#797997"
    />
    <path
      d="M31.5608 38.6017L29.5107 44.5739C30.1346 44.7522 30.7809 44.8636 31.4494 44.8636C32.2516 44.8636 33.0093 44.7299 33.7224 44.4848C33.7224 44.4625 33.6778 44.4179 33.6778 44.3956L31.5608 38.624V38.6017Z"
      fill="#797997"
    />
    <path
      d="M37.4661 34.702C37.4884 34.9249 37.5107 35.1477 37.5107 35.4151C37.5107 36.1059 37.377 36.8859 36.9982 37.8664L34.9034 43.9277C36.9536 42.7466 38.3129 40.5182 38.3129 38.0001C38.3129 36.7967 38.0009 35.6825 37.4661 34.702Z"
      fill="#797997"
    />
    <path
      d="M31.4494 30C27.0371 30 23.4494 33.5877 23.4494 38C23.4494 42.4123 27.0371 46 31.4494 46C35.8617 46 39.4494 42.4123 39.4494 38C39.4494 33.5877 35.8617 30 31.4494 30ZM31.4494 45.6435C27.2377 45.6435 23.8059 42.2117 23.8059 38C23.8059 33.7883 27.2377 30.3565 31.4494 30.3565C35.6611 30.3565 39.0929 33.7883 39.0929 38C39.0929 42.2117 35.6611 45.6435 31.4494 45.6435Z"
      fill="#797997"
    />
    <path
      d="M62.4494 7H58.4494"
      stroke="var(--v-illustrations-darken1)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M60.4494 9V5"
      stroke="var(--v-illustrations-darken1)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>