<template>
  <div class="notifications-empty">
    <notificationsManagerEmptyIllustration/>

    <div class="notifications-empty__text">
      <p class="p-3 font-weight-bold">{{ $t('message.emptyTable.notification.title') }}</p>
      <p class="p-3 text-center mt-1">{{ $t('message.emptyTable.notification.description') }}</p>
    </div>
  </div>
</template>

<script>
// import EmptyImage from "../../assets/img/happy-potato.vue";
import notificationsManagerEmptyIllustration from '../illustrations/notifications-manager-empty-illustration.vue';

export default {
  components: {
    notificationsManagerEmptyIllustration,
  },
};
</script>


<style lang="scss" scoped>
.notifications-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 306px;
  height: 217px;
  padding: 16px;
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0px;
      color: map-get($text, heading);
    }
  }
  &__img {
    margin-bottom: 16px;
  }
}
</style>