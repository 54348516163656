<template>
  <div
    class="checkbox-big"
    :class="checked ? 'checkbox-big--checked' : ''"
    @click="check"
  >
    <div class="checkbox-big__check">
      <v-icon size="14">$check</v-icon>
    </div>
    <v-img
      v-if="img && !hasSlot"
      class="checkbox-big__icon"
      :src="img"
      width="48px"
      height="48px"
    />
    <div class="checkbox-big__icon" v-else-if="hasSlot">
      <slot> </slot>
    </div>

    <span class="checkbox-big__title">
      {{ title }}
    </span>
    <slot name="custom"> </slot>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    img: String,
    title: String,
  },
  methods: {
    check: function () {
      this.$emit("check", !this.checked);
    },
  },
  computed: {
    hasSlot: function () {
      return this.$slots.default ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-big {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 60px;
  padding: 16px;
  position: relative;
  border: 1px solid var(--v-gray-lighten2);
  transition: border 0.24s ease;
  border-radius: 4px;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 4px;
    border: 2px solid transparent;
    transition: border 0.24s ease;
  }
  &:hover,
  &--checked {
    border: 1px solid transparent;

    &::after {
      border: 2px solid map-get($primary, base);
    }
  }
  &__icon {
    margin-bottom: 16px;
    max-height: 64px;
    max-width: 64px;
  }
  &__title {
    color: map-get($text, headings);
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }
  &__check {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.24s ease;
    .v-icon {
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
      z-index: 999;
    }
    &::after {
      content: "";
      transform-origin: center center;
      position: absolute;
      left: 13px;
      top: -24px;
      width: 48px;
      height: 48px;
      transform: rotate(45deg);
      background: map-get($primary, base);
    }
  }
  &--checked {
    .checkbox-big {
      &__check {
        opacity: 1;
      }
    }
  }
}
.v-application--is-rtl{
    .checkbox-big{
        &__check{
            left: 1px;
            top: 1px;
            right: unset;
            rotate: 270deg;
            .v-icon{
                rotate: 90deg;
            }
        }
    }
}
</style>