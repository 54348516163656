<template>
  <div class="table-item item--bandwidth" :class="main ? 'group-start': ''">
    <template v-if="main">
      <div class="item item--main">
        <p class="item__value" v-if="item.hasActionInProgress()">
          <v-icon class="text--gray-lighten1" :size="20">$loader</v-icon>
        </p>
        <p class="item__value" v-else-if="item.info.bandwidth.current === '-' || item.info.bandwidth.current === '0 B' || item.info.bandwidth.current === '0 kB' || item.info.bandwidth.current === '0 GB'">
          <span class="p-4 text--gray-lighten1">{{ $t('general.nodata') }}</span>
        </p>
        <p class="item__value" v-else>
          <span class="item__value-current p-2">{{
            item.info.bandwidth.current
          }}</span>
          /
          <span class="item__value-max p-3 d-block">{{ item.info.bandwidth.max }}</span>
        </p>
      </div>
    </template>
    <template v-else>
      <div class="item">
        <span class="p-4 text--gray-lighten1">{{ $t('general.nodata') }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    main: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>
.table-item {
  max-width: 132px;
  min-width: 132px;
}
</style>