<template>
  <div
    class="table-item table-item--name item--title"
    :class="main ? 'group-start' : ''"
  >
    <template v-if="main">
      <div class="item item--main">
        <component
          :is="item.hasInstallFailed() ? 'span' : 'router-link'"
          :to="
            item.hasInstallFailed()
              ? undefined
              : `/instances/${item.id}/general`
          "
        >
          <instance-avatar
            :instance="item"
            :size="40"
            :installFailed="item.hasInstallFailed()"
            :ssl="item.info.ssl"
            @dns-propagation-in-progress="isDnsPropagationInProgress = true"
          />
        </component>
        <div class="item__title-box">
          <div class="d-flex align-center">
            <component
              :is="!item.hasInstallInProgress() ? 'router-link' : 'div'"
              :to="item.isActive ? `/instances/${item.id}/general` : ''"
              class="d-flex align-center mw-100"
            >
              <h6 class=" item__title text-truncate">
                {{ item.title }}
              </h6>
              <div
                class="suspend-chip ml-2"
                v-if="item.service_status === 'suspended'"
              >
                <v-icon color="sand darken-2" size="12" class="mr-1">
                  $suspended
                </v-icon>
                {{ $t('message.status.suspended') }}
              </div>
            </component>
            <v-menu
              :max-width="'292px'"
              nudge-top="38px"
              :nudge-left="$vuetify.rtl ? '-100px' : '100px'"
              transition="custom-tooltip"
              open-delay="150"
              origin="top center"
              center
              bottom
              open-on-hover
              :close-on-content-click="false"
              v-if="item.hasInstallFailed() && item.service_status !== 'suspended'"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-icon
                    :color="'error'"
                    size="16"
                    v-bind="attrs"
                    v-on="on"
                    @mouseenter="showTooltip = true"
                    
                    class="ml-2 item__tooltip item__tooltip--failed"
                  >
                    $alertcircle
                  </v-icon>
                </div>
              </template>
              <span>
                <p class="item__tooltip-content py-3 px-4 mb-0 p-sm">
                  {{ $t('message.status.instanceInstall.failed') }} <a class="text-underline info--text" @click.stop.prevent="retryInstall">{{ $t('button.try') }}</a>
                </p>
              </span>
            </v-menu>
          </div>
          <a :href="item.url" class="item__url instance-domain-link p-4">{{
            item.url
          }}</a>

          <preview-site-button
            v-if="
              $store.state.home.app.integrations.preview_site_provider_enabled
              && isDnsPropagationInProgress
            "
            :instance="item"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="item">
        <div class="item__title-box">
          <router-link :to="`/instances/${item.subinstance.id}/general`">
            <p class="mb-0 p-2 font-weight-600 item__title">
              <v-tooltip
                transition="custom-tooltip"
                open-delay="150"
                bottom
                z-index="99"
                offset-overflow
                nudge-bottom="12px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-inline" v-on="on" v-bind="attrs">
                    <label-chip
                      class="subinstance-label"
                      xsmall
                      :item="{
                        title: item.subinstance.subinstanceLabel,
                        color: 'info lighten-4 info--text',
                      }"
                    />
                  </div>
                </template>

                <span>
                  {{ $t('message.instance.hasSubinstance', {label: item.subinstance.subinstanceLabel}) }}
                </span>
              </v-tooltip>
              {{ item.subinstance.title }}
            </p>
          </router-link>

          <a
            :href="item.subinstance.url"
            class="item__url p-4 instance-domain-link"
            >{{ item.subinstance.url }}</a
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InstanceAvatar from "../../InstanceAvatar.vue";
import LabelChip from "../../labels/LabelChip.vue";
import PreviewSiteButton from "../../backups/PreviewSiteButton.vue";

export default {
  components: {PreviewSiteButton, InstanceAvatar, LabelChip },
  props: {
    item: {
      type: Object,
    },
    main: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      isDnsPropagationInProgress: false,
      showTooltip: true,
    }
  },
  methods: {
    retryInstall() {
      this.$root.$emit("instance-retry-install", this.item);
    }
  }
};
</script>

<style lang="scss" scoped>
.table-item {
  padding-left: 0;
}

.item {
  max-width: 100%;
  width: 100%;
  &__title {
    color: map-get($text, headings);
    transition: color 0.24s ease;
    &:hover {
      color: map-get($primary, darken1);
    }
  }
  &__title-box {
    max-width: calc(100% - 40px);
    > a {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &:not(.item--main) {
    margin-left: 56px;
    position: relative;
    &::after {
      content: "";
      position: absolute;

      border-bottom: 1px solid var(--v-gray-lighten3);
      border-left: 1px solid var(--v-gray-lighten3);

      width: 30px;
      height: 68px;
      left: -36px;
      top: -32px;
    }
  }
}

.subinstance-label {
  text-transform: capitalize;
}

.suspend-chip {
  border-radius: 5px;
  background-color: var(--v-sand-lighten5);
  color: var(--v-sand-darken2);
  font-weight: $font-weight-bold;
  font-size: $font-size-xxs;
  line-height: $line-height-xxs;
  padding: 4px;
}
.v-application--is-rtl{
        .item {
            &:not(.item--main) {
            margin-right: 56px;
            &::after {
            border-bottom: unset;
            border-top: 1px solid map-get($gray, base);
            left: unset;
            right: -36px;
            top: -32px;
            rotate: 180deg;
            }
            .item__title-box{
                margin-right: 0px;
            }
        }
    }
}
.mw-100{
  max-width: 100%;
}
.item__tooltip{
  &.item__tooltip--failed{
    cursor: pointer;
  }
}
</style>
