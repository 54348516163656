const InstallationModule = {
    state: {
        displaySelectProductModal: false,
        displayInstallationProgress: false,
        displaySuperQuickInstallationProgress: false,
        displayStandardForm: false,
        displayLimitModal: false,
        displayZeroDataModal: false,
        displayChooseTemplate: false,
        displayWebsiteInstallationModal: false,
        displayWebsiteDomainModal: false,
        displayChooseUserConfigurableOptionsModal: false,
        displayChooseOnboardingTypeModal: false,
        displayChooseCreationTypeModal: false,
        displayImportInstanceUrlSettingsModal: false,
        displayFtpAccountConfigurationModal: false,
        displayChooseSiteUserConfigurationOptionsModal: false,
        displayImportInstanceSetupCompleteModal: false,
        displayImportChooseServiceModal: false,
        displayVerificationDomainModal: false,
    },
    mutations: {
        deactivateAll(state) {
            state.displaySelectProductModal = false
            state.displayInstallationProgress = false
            state.displaySuperQuickInstallationProgress = false
            state.displayLimitModal = false
            state.displayZeroDataModal = false
            state.displayChooseTemplate = false
            state.displayStandardForm = false
            state.displayWebsiteInstallationModal = false
            state.displayWebsiteDomainModal = false
            state.displayChooseUserConfigurableOptionsModal = false
            state.displayChooseOnboardingTypeModal = false
            state.displayChooseCreationTypeModal = false
            state.displayImportInstanceUrlSettingsModal = false
            state.displayFtpAccountConfigurationModal = false
            state.displayChooseSiteUserConfigurationOptionsModal = false
            state.displayImportInstanceSetupCompleteModal = false
            state.displayImportChooseServiceModal = false
            state.displayVerificationDomainModal = false
        },
        activateModal(state, payload) {
            state[payload] = true
        },
    },
    actions: {
        displayChooseOnboardingTypeModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayChooseOnboardingTypeModal')
        },
        displayWebsiteInstallationModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayWebsiteInstallationModal')
        },
        displayWebsiteDomainModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayWebsiteDomainModal')
        },
        displayChooseUserConfigurableOptionsModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayChooseUserConfigurableOptionsModal')
        },
        displaySelectProductModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displaySelectProductModal')
        },
        displayInstallationProgress: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayInstallationProgress')
        },
        displaySuperQuickInstallationProgress: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displaySuperQuickInstallationProgress')
        },
        displayLimitModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayLimitModal')
        },
        displayZeroDataModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayZeroDataModal')
        },
        displayChooseTemplate: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayChooseTemplate')
        },
        displayStandardForm: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayStandardForm')
        },
        deactivateAll: function (context) {
            context.commit('deactivateAll')
        },
        displayChooseCreationTypeModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayChooseCreationTypeModal')
        },
        displayImportInstanceUrlSettingsModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayImportInstanceUrlSettingsModal')
        },
        displayFtpAccountConfigurationModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayFtpAccountConfigurationModal')
        },
        displayChooseSiteUserConfigurationOptionsModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayChooseSiteUserConfigurationOptionsModal')
        },
        displayImportInstanceSetupCompleteModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayImportInstanceSetupCompleteModal')
        },
        displayImportChooseServiceModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayImportChooseServiceModal')
        },
        displayVerificationDomainModal: function (context) {
            context.commit('deactivateAll')
            context.commit('activateModal', 'displayVerificationDomainModal')
        },
        startInstallationProcess: function () {
        }
    }
}

export default InstallationModule