import Api from "@/apis/Api"

export default class {
  constructor(id) {
    this.id = id;
    this.sslDomainsFetched = false;
    this.sslDomainsPromise = false;
    this.sslDomains = new Map();
    this.availableCerts = new Map();

    this.installedCertificates = new Map();
    this.detectedCertificates = new Map();
  }

  resetCache() {
    this.sslDomainsFetched = false;
    this.sslDomainsPromise = false;
    this.sslDomains = new Map();
    this.availableCerts = new Map();

    this.installedCertificates = new Map();
    this.detectedCertificates = new Map();
  }

  getAvailableCertificates(domain) {
    if (!this.availableCerts.has(domain)) {
      this.availableCerts.set(domain, Api.get(`/server-accounts/${this.id}/ssl/certificates?domain=${domain}`).then((response) => {
        return response.data.data;
      }));
    }
    return this.availableCerts.get(domain);
  }


  getInstalledCertificate(domain) {
    return new Promise((resolve) => {
      if (this.installedCertificates.has(domain)) {
        resolve(this.installedCertificates.get(domain));
      } else {
        Api.get(`/server-accounts/${this.id}/ssl/certificate/installed?domain=${domain}`)
          .then(response => {
            const cert = response.data.data;
            if (cert) {
              this.installedCertificates.set(domain, cert);
            }
            resolve(cert);
          });
      }
    });
  }

  getDetectedCertificate(domain, skipCache = false) {
    return new Promise((resolve) => {
      if (!skipCache && this.detectedCertificates.has(domain)) {
        resolve(this.detectedCertificates.get(domain));
      } else {
        Api.get(`/server-accounts/${this.id}/ssl/certificate/detected?domain=${domain}`)
          .then(response => {
            const cert = response.data.data;
            if (cert) {
              this.detectedCertificates.set(domain, cert);
            }
            resolve(cert);
          });
      }
    });
  }
}