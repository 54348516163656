<template>
  <v-dialog
    no-click-animation
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    fullscreen
    persistent
    :value="isOpen ? true : false"
  >
    <v-card class="installation-card d-flex align-center mb-16">
      <div class="mx-auto align-center px-8 d-flex flex-column mt-8">
        <div class="d-flex justify-center">
          <v-img
            v-if="isLogoBase64 || !customLogo"
            :width="$vuetify.breakpoint.mobile ? 171 : 200"
            :height="$vuetify.breakpoint.mobile ? 36 : 44"
            :src="customLogo ? customLogo : require(`@/assets/logo${isDark ? '-white' : ''}.svg`)"
            contain
            position="center center"
          />
          <div v-else v-html="customLogo" class="mb-10 d-flex" />
        </div>
        <div class="d-flex justify-center pt-16">
          <h1 class="text--center d-flex flex-column align-center">
            <span class="text-center primary--text" v-if="!error && completed">
              {{ $t("heading.onboarding.installation.title.completed") }}
            </span>
            <span
              class="text-center error--text"
              v-else-if="error && completed"
              >{{ $t("heading.onboarding.installation.title.failed") }}</span
            >
            <span class="text-center" v-if="!completed">{{
              $t("heading.onboarding.installation.subtitle.inProgress")
            }}</span>
            <span class="text-center" v-else-if="completed && !error">{{
              $t("heading.onboarding.installation.subtitle.completed")
            }}</span>
            <span class="text-center" v-else-if="completed && error">{{
              $t("heading.onboarding.installation.subtitle.failed")
            }}</span>
          </h1>
        </div>
        <div
          class="mt-8 installation-card__image"
          :class="
            !completed
              ? 'installation-card__image--working'
              : error
              ? 'installation-card__image--error'
              : 'installation-card__image--success'
          "
        >
          <loader
            :completed="completed"
            :size="120"
            :width="12"
            color="#ffffff"
          >
            <v-img v-if="!completed" src="../../assets/LogoPartialWhite.svg" />
            <v-img v-if="completed && !error" src="../../assets/Check.svg" />
            <v-img v-if="completed && error" src="../../assets/Failed.svg" />
          </loader>
          <v-img
            width="100vw"
            position="center top"
            height="315px"
            v-show="!error"
          />
          <v-img
            width="100vw"
            position="center top"
            height="315px"
            v-show="error && completed"
          />
        </div>

        <div v-if="installedInstanceDnsMismatch" class="success-box mt-8">
          <p class="font-weight-600 mb-0">
            <i18next
              :translation="
                $t('heading.onboarding.installation.info.setDnsRecords')
              "
            >
              <template #domain>
                <a
                  :href="installedInstance.url"
                  class="font-weight-600"
                  target="_blank"
                  >{{ installedInstance.domain }}</a
                >
              </template>
            </i18next>
          </p>
          <DnsOptions
            :domain="installedInstance.domain"
            :onboardingSubdomain="installedInstance.onboarding_subdomain"
            :dnsSettings="installedInstance.dns_settings"
            :ipv4Addresses="installedInstance.host_ipv4_addresses"
            :hasDnsServer="installedInstance.service_has_dns"
            :nameservers="installedInstance.dns_nameservers"
            hideTitle
            style="color: #000; text-align: left"
          />
        </div>

        <p
          class="p-1 pt-8 mb-0 text-center heading--text"
          v-if="!error && !installedInstanceDnsMismatch"
        >
          <span v-if="completed">{{ $t("heading.onboarding.installation.info.completed") }}</span>
          <span v-else-if="hasExternalWordPressBuilder">{{ $t("heading.onboarding.installation.info.builderInProgress") }}</span>
          <span v-else>{{ $t("heading.onboarding.installation.info.inProgress") }}</span>
        </p>
        <div
          class="d-flex pt-8"
          :class="$vuetify.breakpoint.mdAndDown ? 'flex-column' : ''"
        >
          <v-btn
            v-if="displayButtons && !error"
            width="210px"
            elevation="0"
            x-large
            outlined
            exact
            color="gray"
            class="v-size--xx-large"
            @click="$emit('gotoDashboard')"
            :class="$vuetify.breakpoint.mdAndDown ? 'mr-0' : ''"
          >
            <span class="heading--text">{{ $t("button.dashboard.go") }}</span>
          </v-btn>
          <v-btn
            v-if="error"
            width="210px"
            elevation="0"
            x-large
            outlined
            color="gray"
            class="v-size--xx-large"
            @click="$emit('tryAgain')"
            :class="$vuetify.breakpoint.mdAndDown ? 'ml-0 mt-4' : ''"
          >
            <span class="heading--text">{{ $t("button.try") }}</span>
          </v-btn>
          <v-btn
            v-if="completed && error"
            width="210px"
            elevation="0"
            x-large
            color="primary"
            class="primary v-size--xx-large mb-10"
            :class="$vuetify.breakpoint.mdAndDown ? 'ml-0 mt-4' : 'ml-6'"
            @click="$emit('gotoDashboard')"
          >
            {{ $t("button.dashboard.back") }}
          </v-btn>
          <v-btn
            v-else-if="displayButtons"
            width="210px"
            elevation="0"
            x-large
            color="primary"
            class="primary v-size--xx-large mb-10"
            :class="$vuetify.breakpoint.mdAndDown ? 'ml-0 mt-4' : 'ml-6'"
            :disabled="!completed || error"
            @click="manageSite(installedInstance.id)"
          >
            {{ $t("button.website.manage") }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Loader from "../Loader.vue";
import DnsOptions from "../DnsOptions.vue";

export default {
  components: {
    Loader,
    DnsOptions,
  },
  props: {
    isOpen: Boolean,
    theme: Object,
    completed: Boolean,
    error: Boolean,
    installedInstance: Object,
    installedInstanceDnsMismatch: Boolean,
    service: Object
  },
  watch: {
    isOpen: function (value) {
      if (value) {
        this.$store.dispatch("lockBodyScroll");
      } else {
        this.$store.dispatch("unlockBodyScroll");
      }
    },
  },
  methods: {
    manageSite(id) {
      if (this.isDemo) {
        this.$root.$emit("manage-first-instance");
        this.$store.dispatch("deactivateAll");
        return;
      }

      this.$store.dispatch("deactivateAll");
      this.$router.push(
        id
          ? {
              path: `/instances/${id}/general`,
            }
          : undefined
      );
    },
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
    isDemo() {
      return this.$store.state.home.user.email == "demo@panelalpha.com";
    },
    customLogo: function () {
      if(this.$vuetify.theme.dark && this.$store.state.ThemeModule.darkLogo) {
        return this.$store.state.ThemeModule.darkLogo
      }

      if(!this.$vuetify.theme.dark && this.$store.state.ThemeModule.lightLogo) {
        return this.$store.state.ThemeModule.lightLogo
      }

      return false
    },
    isLogoBase64: function () {
      return (
        this.customLogo && this.customLogo.trim().startsWith("data:image/")
      );
    },
    hasExternalWordPressBuilder() {
      return this.installedInstance?.plan_settings?.onboarding?.has_external_wordpress_builder ?? false;
    },
    displayButtons() {
      return this.service?.onboarding?.super_quick_post_installation_action !== 'redirect' ?? true;
    }
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  overflow: auto !important;
}

.installation-card {
  max-height: 100vw;
  max-width: 100vw;
  display: flex;
  align-items: center;
  background: var(--v-body-base) !important;

  > div {
    max-width: 684px;
    width: 100%;
  }

  .success-box {
    width: 100%;
    background: var(--v-primary-lighten4);
    color: var(--v-primary-base);
    padding: 16px;
    text-align: center;
    border-radius: 8px;
  }

  .form-box {
    background-color: #fcfcff;
    border: 1px solid var(--v-gray-lighten4);
    border-radius: 8px;
    padding: 16px;
  }

  &__image {
    width: 100%;
    position: relative;
    border-radius: 16px !important;
    box-shadow: 0px 12px 32px 6px rgba(0, 0, 0, 0.06),
      0px 16px 72px 8px rgba(0, 0, 0, 0.1);

    > .v-image {
      border-radius: 16px;
      position: relative;

      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 0px;
      //   left: 0px;
      //   height: 100%;
      //   width: 100%;
      //   backdrop-filter: blur(8px);
      //   z-index: 90;
      // }
    }

    .loader {
      position: absolute;
      z-index: 100;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      display: flex;
      align-items: center;
    }

    &:before {
      background: linear-gradient(
        180deg,
        var(--v-primary-lighten1) 0%,
        var(--v-primary-base) 100%
      );
      opacity: 1;
    }

    &:after {
      background: linear-gradient(
        180deg,
        var(--v-primary-lighten1) 0%,
        var(--v-primary-base) 100%
      );
      opacity: 0;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      z-index: 99;
      border-radius: 16px;
      transition: opacity 0.5s ease;
      backdrop-filter: blur(8px);
    }

    &--error,
    &--success {
      &:before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }

    &--error {
      &:after {
        background: linear-gradient(
          180deg,
          rgba(241, 40, 40, 0.35) 0%,
          rgba(241, 40, 40, 0.7) 100%
        );
      }
    }

    &--success {
      &:after {
        background: linear-gradient(
          180deg,
          rgba(111, 198, 108, 0.5) 0%,
          #07c07e 100%
        );
      }
    }
  }
}

h1 {
  font-size: 40px;
  line-height: 50px;
}
</style>
  