<template>
  <v-container class="px-0 pb-0 pt-1">
    <v-row>
      <v-col cols="12">
        <v-card class="table w-100">
          <!-- <template v-if="!fetching"> -->
          <v-data-table
            calculate-widths
            :headers="keys"
            :items="itemsLoading ? [] : listSorted"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :search="searchTerm"
            :sort-by="sortBy.value"
            :sort-desc="sortDesc"
            hide-default-header
            hide-default-footer
            class="elevation-4 custom-data-table custom-instance-table"
            :header-props="{ sortIcon: `$arrowup` }"
            :server-items-length="itemsTotal"
            :loading="itemsLoading"
          >
            <template v-slot:no-data>
              <data-iterator-no-results-container
                v-if="searchTerm || filterLabels"
                @clearAllFilters="$emit('clearAllFilters')"
                :searchTerm="searchTerm"
                :filterLabels="filterLabels"
              />
            </template>

            <template v-slot:no-results>
              <data-iterator-no-results-container
                @clearAllFilters="$emit('clearAllFilters')"
                :searchTerm="searchTerm"
              />
            </template>

            <template v-slot:loading>
              <instance-table-item-skeleton
                v-for="(_, index) in 10"
                :key="index"
              />
            </template>

            <template v-slot:item="{ item }">
              <div
                class="
                  d-flex
                  table-row
                  flex-xl-row flex-column
                  position-relative
                "
                :class="
                  item.service_status === 'suspended'
                    ? 'table-row--suspended'
                    : ''
                "
              >
                <div
                  v-if="item.service_status === 'suspended'"
                  class="instance-actions instance-actions--suspended"
                  :class="unsuspendUrl() ? 'cursor-pointer' : ''"
                  @click="unsuspendUrl() ? unsuspend() : undefined"
                >
                  <div class="d-flex align-center justify-center">
                    <v-icon size="32" color="sand darken-1" class="mr-2">
                      $suspended
                    </v-icon>

                    <p
                      class="
                        font-weight-600
                        p-2
                        sand--text
                        text--darken-1 text-center
                        mb-0
                      "
                    >
                      {{ $t('message.serviceSuspended') }}
                    </p>
                  </div>
                </div>

                <select-item
                  :id="item.id"
                  :main="true"
                  @selectItem="handleSelectItem(item)"
                  :checked="checkedItems.includes(item.id)"
                />

                <name-item main :item="item" />

                <status-item main :item="item.subInstances[0]" />

                <visitors-item main :item="item" />

                <bandwidth-item main :item="item" />

                <storage-item main :item="item" />

                <tags-item
                  main
                  :key="item.subInstances[0].id"
                  :item="item.subInstances[0]"
                  @toggleFilter="toggleFilter"
                />

                <!-- <div class="table-item">
                  <div class="item" style="width: 60px"></div>
                </div> -->

                <shared-item :item="item" main />

                <div class="table-item p-4 d-flex align-center">
                  {{ item.created_at_from_now }}
                </div>

                <div class="table-item ml-auto">
                  <div class="item">
                    <instance-item-menu
                      main
                      :id="item.id"
                      :itemTitle="item.title"
                      :subinstance="item.subInstances[0]"
                      :instance="item"
                      v-on="$listeners"
                      @cancelInstallation="cancelInstall()"
                      @restartInstallation="retryInstall()"
                    />
                  </div>
                </div>
              </div>
              <div
                class="d-flex flex-column"
                v-if="item.subInstances.length > 1"
              >
                <div
                  class="d-flex table-subrow flex-column flex-xl-row"
                  v-for="(
                    subinstance, subinstanceIndex
                  ) in item.subInstances.slice(1, item.subInstances.length)"
                  :key="subinstanceIndex"
                >
                  <select-item :id="item.id" />

                  <name-item
                    :index="subinstanceIndex"
                    :item="{ ...item, subinstance: subinstance }"
                  />

                  <status-item :item="subinstance" />

                  <visitors-item :item="item" />

                  <bandwidth-item :item="item" />

                  <storage-item :item="item" />

                  <tags-item :key="subinstance.id" :item="subinstance" />

                  <!-- <div class="table-item">
                    <div class="item" style="width: 60px"></div>
                  </div> -->

                  <shared-item :item="subinstance" />

                  <div class="table-item p-4 d-flex align-center">
                    {{ subinstance.created_at_from_now }}
                  </div>

                  <div class="table-item ml-auto">
                    <div class="item">
                      <instance-item-menu
                        :id="subinstance.id"
                        :itemTitle="subinstance.title"
                        :subinstance="subinstance"
                        :instance="{ ...item, subinstance: subinstance }"
                        v-on="$listeners"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:header="{ isMobile, props, on }">
              <instance-table-custom-sort
                :isMobile="isMobile"
                :props="props"
                :v-on="on"
                :allSelected="allSelected"
                @selectAll="handleSelectAll"
                v-on="$listeners"
              />
            </template>

            <!-- CELLS END -->

            <template v-slot:footer>
              <v-divider
                v-if="
                  !(
                    numberOfPages === 1 &&
                    itemsPerPage === possibleItemsPerPage[listStyle][0]
                  )
                "
              />
              <data-iterator-footer
                :page="page"
                :numberOfPages="numberOfPages"
                :possibleItemsPerPage="possibleItemsPerPage"
                :listStyle="listStyle"
                :itemsPerPage="itemsPerPage"
                :itemsTotal="itemsTotal"
                v-on="$listeners"
                :perPageLabel="$t('table.footer.instance')"
              >
              </data-iterator-footer>
            </template>
          </v-data-table>
          <!-- </template> -->
          <!-- <template v-else> -->

          <!-- </template> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataIteratorMixin from "../../mixins/DataIteratorMixin";
import DataIteratorFooter from "../dataIterator/DataIteratorFooter.vue";

import NameItem from "./tableItems/NameItem.vue";
import BandwidthItem from "./tableItems/BandwidthItem.vue";
import StatusItem from "./tableItems/StatusItem.vue";
import StorageItem from "./tableItems/StorageItem.vue";
import VisitorsItem from "./tableItems/VisitorsItem.vue";
import TagsItem from "./tableItems/TagsItem.vue";
import SelectItem from "./tableItems/SelectItem.vue";
import DataIteratorNoResultsContainer from "../dataIterator/DataIteratorNoResultsContainer.vue";
import InstanceItemMenu from "./InstanceItemMenu.vue";
import InstanceTableCustomSort from "./InstanceTableCustomSort.vue";
import InstanceTableItemSkeleton from "./InstanceTableItemSkeleton.vue";
import SharedItem from "./tableItems/SharedItem.vue";

export default {
  mixins: [DataIteratorMixin],
  components: {
    DataIteratorFooter,
    NameItem,
    BandwidthItem,
    StatusItem,
    StorageItem,
    VisitorsItem,
    TagsItem,
    SelectItem,
    InstanceItemMenu,
    InstanceTableCustomSort,
    DataIteratorNoResultsContainer,
    InstanceTableItemSkeleton,
    SharedItem,
  },
  props: {
    checkedItems: {
      type: Array,
    },
    itemsTotal: Number,
    itemsLoading: Boolean,
    filterLabels: Array,
  },
  watch: {
    items: function () {
      this.sortSubinstances();
    },
  },
  created: function () {
    this.sortSubinstances();
  },
  computed: {
    allSelected: function () {
      return this.checkedItems.length === this.items.length;
    },
    headers: function () {
      return this.keys.map((item) => {
        return item;
      });
    },
  },
  data: function () {
    return {
      listSorted: this.items,
      listSortBy: "title",
    };
  },
  methods: {
    unsuspend() {
      // if (this.unsuspendUrl()) {
      this.$emit("unsuspend", this.unsuspendUrl());
      // }
    },
    unsuspendUrl() {
      return this.$store.state?.home?.user?.service_unsuspend_link
        ? this.$store.state.home.user.service_unsuspend_link
        : true;
    },
    toggleFilter: function (item) {
      this.$emit("toggleFilter", item);
    },
    sortSubinstances: function () {
      let newArray = this.items;
      this.listSorted = newArray.map((group) => {
        if (group.subInstances.length > 1) {
          let filteredSubinstances = [...group.subInstances];
          filteredSubinstances.splice(0, 1);

          group.subInstances = [group.subInstances[0]];

          const subinstances = filteredSubinstances.sort((item, item2) => {
            if (this.sortBy.text == "Title") {
              let compare = item.title.localeCompare(item2.title);
              return this.sortDesc ? -compare : compare;
            } else if (this.sortBy.text == "Status") {
              if (item.status.value > item2.status.value) {
                return this.sortDesc ? -1 : 1;
              } else if (item.status.value < item2.status.value) {
                return this.sortDesc ? 1 : -1;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          });

          group.subInstances = group.subInstances.concat(subinstances);

          return group;
        } else {
          return group;
        }
      });
    },
    handleSelectAll(data) {
      this.$emit("update:check-all", data);
    },
    handleSelectItem(item) {
      this.$emit("update:checked-items", item);
    },
    changeSortBy(data) {
      this.$emit("update:sort-by", data);
    },
    changeSortDesc(data) {
      this.$emit("update:sort-desc", data);
    },
    changeSortByToList(newValue) {
      let newSortBy = this.sortBy;

      this.headers.forEach((item) => {
        if (item.value == newValue) {
          newSortBy = item;
        }
      });

      this.listSortBy = newSortBy.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card.table {
  border-radius: 8px;
}
.v-data-table::v-deep {
  .custom-sort {
    padding: 0px;
    .sort-item {
      &:first-child {
        margin-left: 24px !important;
      }
      //   &:last-child {
      //     padding-right: 24px;
      //   }
    }
  }
  .table-row,
  .table-subrow {
    .table-item {
      max-height: 66px;
      min-height: 66px;
    }
  }
  .table-row {
    position: relative;
    &:hover {
      background: #eee !important;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: var(--v-gray-lighten2);
      right: 0;
    }
  }
  .table-subrow {
    border-top: none;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 1200px;
      height: 1px;
      background: var(--v-gray-lighten2);
      right: 0;
    }
  }
  .text-start {
    max-width: 300px;
  }
  .v-row-group__header {
    background: transparent !important;
    position: relative;
    .checkbox {
      left: 24px;
      top: 8px;
      position: absolute;
    }
  }
  .status-indicator-container {
    display: flex;
    align-items: center;
  }
  .table-item {
    &--first {
      padding-left: 48px;
    }
  }
  .item {
    min-height: 66px;
    max-height: 66px;
    display: flex;
    align-items: center;
    &--main {
      .item__title-box {
        margin-left: 16px;
      }
    }
    .item__value {
      margin: 0;
      .item__value-current {
        color: map-get($text, headings);
        font-weight: $font-weight-semibold;
      }
    }
    .no-data {
      color: map-get($text, headings);
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-semibold;
    }
  }
  .item__title-box {
    display: flex;
    flex-direction: column;
    .item__title {
      margin: 0;
    }
    .item__url {
      color: map-get($gray, darken1);
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}

.table-row {
  &--suspended {
    border-left: 3px solid var(--v-sand-base);
  }
  &:hover {
    .instance-actions {
      opacity: 1;
    }
  }
}
.instance-actions {
  position: absolute;
  top: 0;
  height: 200px;
  z-index: 50;
  width: 100%;
  transition: opacity 0.2s ease;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  &--suspended {
    background: transparent;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    top: 0px;
    border-top: 1px solid var(--v-gray-lighten2);
    backdrop-filter: blur(13.5px);
  }
}

.v-application--is-rtl .v-data-table::v-deep {
  .custom-sort {
    .sort-item {
      &:first-child {
        margin-left: 0px !important;
        margin-right: 24px;
      }
    }
  }
}
.v-application--is-rtl{
    .table-subrow{
    &::after{
        right: unset;
        left: 0px;
    }
  }
}

</style>
