<template>
    <svg :width="width" :height="height" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.816 7.752C12.6682 7.66075 12.4772 7.67752 12.3492 7.79455C11.4167 8.64038 10.2104 9.10644 8.95329 9.10644C6.16299 9.10644 3.89281 6.83625 3.89281 4.04595C3.89281 2.78872 4.35887 1.58256 5.20469 0.65005C5.32133 0.521509 5.33889 0.331079 5.24724 0.183198C5.1556 0.035714 4.97618 -0.0327218 4.81044 0.0140923C1.97789 0.81618 0 3.43459 0 6.3815C0 10.0306 2.96863 12.9991 6.61765 12.9991C9.56455 12.9991 12.1831 11.0213 12.9852 8.1888C13.0327 8.02148 12.9635 7.84355 12.816 7.752ZM5.91211 4.824L6.87453 5.30694L7.35532 6.27375C7.42395 6.41203 7.56483 6.49926 7.71874 6.49926C7.87276 6.49926 8.01312 6.41203 8.08216 6.27375L8.56347 5.30694L9.52548 4.824C9.66315 4.75466 9.74999 4.61357 9.74999 4.45897C9.74999 4.30437 9.66315 4.16328 9.52548 4.09393L8.56347 3.61089L8.08216 2.64418C7.94408 2.36762 7.49268 2.36762 7.35532 2.64418L6.87453 3.61089L5.91211 4.09393C5.77444 4.16286 5.68749 4.30437 5.68749 4.45897C5.68749 4.61357 5.77444 4.75507 5.91211 4.824ZM10.5625 2.43679H10.1563C9.94115 2.43679 9.75002 2.24576 9.75002 2.03054C9.75002 1.81541 9.94115 1.62429 10.1563 1.62429H10.5668V1.20108C10.5668 0.985853 10.741 0.811787 10.9561 0.811787C11.1712 0.811787 11.375 0.985853 11.375 1.20108V1.62429L11.7813 1.62429C11.9964 1.62429 12.1875 1.81541 12.1875 2.03054C12.1875 2.24576 11.9964 2.43679 11.7813 2.43679H11.375V2.84304C11.375 3.05816 11.1839 3.24929 10.9688 3.24929C10.7536 3.24929 10.5625 3.05816 10.5625 2.84304V2.43679Z" :fill="iconColor"/>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>