<template>
  <div class="no-results-container">
    <div class="text-center" v-if="searchTerm || filterLabels.length">
      <div
        class="d-flex flex-column align-center justify-center mx-auto pa-6"
        style="max-width: 510px"
      >
        <dataliteratorNoResultIllustration/>

        <span class="gray--text text--darken-1 mb-6 mt-7">
          {{ $t("message.emptyTable.instances.filter.title") }}
        </span>

        <v-btn
          text
          color="primary"
          @click="$emit('clearAllFilters')"
          class="px-3"
        >
          <v-icon color="primary" size="16" class="mr-2"> $xcircle </v-icon>
          <span class="p-2 font-weight-normal">{{ $t('button.clearFilter') }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import dataliteratorNoResultIllustration from '../illustrations/dataliterator-no-result-illustration.vue';
export default {
  components: {
    dataliteratorNoResultIllustration
  },
  props: {
    searchTerm: {
      type: String,
      default: "",
    },
    filterLabels: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.no-results-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 40px;
  border: 1px dashed map-get($gray, lighten2);
  border-radius: 8px;
  margin: 16px 0 32px 0;
  > div {
    + div {
      margin-top: 4px;
    }
  }
}
</style>
