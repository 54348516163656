<template>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_463_50982)">
        <path d="M8.23992 3.98047C6.03015 3.98047 4.23242 5.77855 4.23242 7.98832C4.23242 10.1981 6.03015 11.9962 8.23992 11.9962C10.4493 11.9962 12.2474 10.1985 12.2474 7.98832C12.2474 5.77819 10.4493 3.98047 8.23992 3.98047Z" :fill="iconColor"/>
        <path d="M13.332 7.988C13.332 7.5561 13.7051 7.20605 14.1654 7.20605H15.4987C15.959 7.20605 16.332 7.5561 16.332 7.988C16.332 8.41989 15.959 8.76959 15.4987 8.76959H14.1654C13.7051 8.76959 13.332 8.41989 13.332 7.988Z" :fill="iconColor"/>
        <path d="M3.33203 8.03951C3.33203 7.57917 2.95892 7.20605 2.49857 7.20605H1.16549C0.705143 7.20605 0.332031 7.57917 0.332031 8.03951C0.332031 8.49986 0.705143 8.8726 1.16549 8.8726H2.49895C2.95892 8.8726 3.33203 8.49986 3.33203 8.03951Z" :fill="iconColor"/>
        <path d="M8.38407 13.0391C8.81597 13.0391 9.16602 13.4121 9.16602 13.8724L9.16602 15.2057C9.16602 15.666 8.81597 16.0391 8.38407 16.0391C7.95218 16.0391 7.60248 15.666 7.60248 15.2057L7.60248 13.8724C7.60248 13.4121 7.95218 13.0391 8.38407 13.0391Z" :fill="iconColor"/>
        <path d="M8.33256 3.03906C8.7929 3.03906 9.16602 2.66595 9.16602 2.2056L9.16602 0.872522C9.16602 0.412174 8.7929 0.0390625 8.33256 0.0390625C7.87221 0.0390624 7.49947 0.412174 7.49947 0.872522L7.49947 2.20598C7.49947 2.66595 7.87221 3.03906 8.33256 3.03906Z" :fill="iconColor"/>
        <path d="M4.83185 11.6111C5.13724 11.9165 5.12097 12.4279 4.79549 12.7533L3.85272 13.6961C3.52724 14.0216 3.01592 14.0379 2.71053 13.7325C2.40513 13.4271 2.42166 12.916 2.74713 12.5905L3.68991 11.6477C4.01539 11.3223 4.52645 11.3058 4.83185 11.6111Z" :fill="iconColor"/>
        <path d="M11.8675 4.50365C12.193 4.82917 12.7206 4.82917 13.0461 4.50366L13.9888 3.56102C14.3143 3.23551 14.3143 2.70785 13.9888 2.38233C13.6633 2.05682 13.1359 2.05709 12.8104 2.3826L11.8675 3.3255C11.5422 3.65075 11.5419 4.17814 11.8675 4.50365Z" :fill="iconColor"/>
        <path d="M4.79706 4.50372C4.49166 4.80912 3.98034 4.79284 3.65487 4.46737L2.71209 3.52459C2.38662 3.19912 2.37034 2.6878 2.67574 2.3824C2.98113 2.07701 3.4922 2.09353 3.81768 2.41901L4.76045 3.36179C5.08593 3.68726 5.10245 4.19833 4.79706 4.50372Z" :fill="iconColor"/>
        <path d="M11.9036 11.5384C11.5781 11.8639 11.5781 12.3915 11.9036 12.717L12.8462 13.6597C13.1717 13.9852 13.6994 13.9852 14.0249 13.6597C14.3504 13.3342 14.3501 12.8068 14.0246 12.4813L13.0817 11.5384C12.7565 11.2131 12.2291 11.2128 11.9036 11.5384Z" :fill="iconColor"/>
        </g>
        <defs>
        <clipPath id="clip0_463_50982">
        <rect width="16" height="16" fill="white" transform="translate(0.332031)"/>
        </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>