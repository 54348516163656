const themeSettings = {
    options: {
        customProperties: true
    },
    themes: {
        light: {
            body: "#FFFFFFF",
            panel: "#FFFFFF",
            link: "#005EEA",
            checkbox: "#FFFFFF",
            field: "#FFFFFF",
            icon: "#797997",
            white: "#FFFFFF",
            nav: "#FFFFFF",
            tooltip: "#FFFFFF",
            darkicon: "#21212B",
            primary: {
                base: "#07C07E",
                lighten1: '#1FD895',
                lighten2: '#CDF2E5',
                darken1: '#0AAA71'
            },
            gray: {
                base: '#CBCBDC',
                darken1: '#797997',
                darken2: '#49495F',
                lighten1: '#9494AD',
                lighten2: '#E5E5F0',
                lighten3: '#E5E5F0',
                lighten4: '#E5E5F0',
                lighten5: '#F2F2F7',
                lighten6: '#F9F9FB',
            },
            // secondary: "#cddc39",
            // accent: "#ffeb3b",
            error: '#E71D1D',
            warning: "#FF7A2F",
            massaction: "#21212B",
            info: {
                base: "#005EEA",
                lighten4: '#CCDFFB',
            },
            success: "#07C07E",
            text: {
                base: '#49495F',
                darken1: '#333342',
                darken2: '#21212B',
                lighten1: '#9494AD'
            },
            sand: {
                lighten5: '#FFF7E5',
                base: '#FFCD51',
                darken1: '#FFB600',
                darken2: '#DB9C00',
            },
        },
        dark: {
            body: "#21212B",
            primary: {
                base: "#07C07E",
                lighten1: '#1FD895',
                darken1: '#0AAA71'
            },
            panel: "#2F2F3D",
            gray: "#333342",
            link: "#0C9EF1",
            secondary: '#424242',
            tooltip: "#131216",
            accent: '#82B1FF',
            darkicon: "#FFFFFF",
            white: "#FFFFFF",
            massaction: "#131216",
            error: '#D14F4F',
            info: {
                base: "#0C9EF1",
                lighten4: '#CCDFFB',
            },
            success: "#07C07E",
            warning: '#FFC107',
            text: {
                base: "#FFFFFF",
                darken1: "#FFFFFF",
                darken2: "#FFFFFF",
            },
            icon: "#FFFFFF",
            checkbox: "#2F2F3D",
            field: "#333342",
            nav: "#21212B",
        },
    }
}

export default themeSettings