<template>
  <v-app-bar
    app
    elevation="2"
    color="white"
    class="app-bar"
    :height="
      $vuetify.breakpoint.smAndDown
        ? '100'
        : $vuetify.breakpoint.mobile
        ? '64'
        : '88'
    "
  >
    <v-container class="px-1">
      <div class="d-flex justify-space-between align-center">
        <v-toolbar-title class="d-flex align-center">
          <router-link to="/" class="logo-link">
            

            <v-img
              v-if="isLogoBase64 || !customLogo"
              :width="$vuetify.breakpoint.mobile ? 171 : 200"
              :height="$vuetify.breakpoint.mobile ? 36 : 44"
              :src="customLogo ? customLogo : require(`@/assets/logo${isDark ? '-white' : ''}.svg`)"
              contain
              :position="$vuetify.rtl ? 'center right' : 'center left'"
            ></v-img>
            <div v-else v-html="customLogo" class="logo-link__svg" 
              :style="$vuetify.breakpoint.mobile ? 'width: 171px; height: 36px' : 'width: 200px; height: 44px'" 
            >

            </div>
            
            
          </router-link>

          <navbar-top-navigation
            class="ml-11"
            v-if="!$vuetify.breakpoint.smAndDown"
          />

          <v-btn
            text
            color="info"
            v-if="$store.state.demoMode"
            href="https://www.panelalpha.com/"
            class="ml-2"
          >
            <span
              v-if="!$vuetify.breakpoint.mobile"
              class="p-2 font-weight-light"
            >
              {{ $t('button.website.visit') }}
            </span>
            <v-icon size="16" color="info" class="ml-2">$externallink</v-icon>
          </v-btn>
        </v-toolbar-title>

        <div class="navbar__menu-items" v-if="loggedIn">
          <add-new-instance-button
            v-if="!$vuetify.breakpoint.mobile"
          ></add-new-instance-button>

          <notifications-dropdown />

          <user-info-dropdown></user-info-dropdown>
        </div>
      </div>
      <div
        v-if="$vuetify.breakpoint.smAndDown"
        class="d-flex justify-space-between align-center"
      >
        <navbar-top-navigation />
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import AddNewInstanceButton from "./AddNewInstanceDialogButton.vue";
import NotificationsDropdown from "./NotificationsDropdown.vue";
import UserInfoDropdown from "./UserInfoDropdown.vue";
import NavbarTopNavigation from "./NavbarTopNavigation.vue";

import Auth from "@/apis/Auth";

export default {
  components: {
    AddNewInstanceButton,
    NotificationsDropdown,
    UserInfoDropdown,
    NavbarTopNavigation,
  },
  data() {
    return {
      loggedIn: false,
    };
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
    customLogo: function () {
      if(this.$vuetify.theme.dark && this.$store.state.ThemeModule.darkLogo) {
        return this.$store.state.ThemeModule.darkLogo
      }

      if(!this.$vuetify.theme.dark && this.$store.state.ThemeModule.lightLogo) {
        return this.$store.state.ThemeModule.lightLogo
      }

      return false
    },
    isLogoBase64: function() {
      return this.customLogo &&  this.customLogo.trim().startsWith('data:image/');

    }
  },
  methods: {
  },
  mounted() {
    this.$root.$on("loggedIn", () => {
      this.loggedIn = true;
    });
    this.$root.$on("loggedOut", () => {
      this.loggedIn = false;
    });
    this.loggedIn = Auth.isLoggedIn();
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  &__menu-items {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
}

.logo-link {
  max-width: 100%;
  .v-image {
      image-rendering: optimizeQuality;
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      -webkit-transform: translate3d(0,0,0);
      &__image {
        -webkit-transform: translate3d(0,0,0);
      }
  }
  .logo-link__svg::v-deep {
    svg {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
</style>
