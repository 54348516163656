<template>
  <v-dialog
    ref="dialog"
    v-model="isOpen"
    transition="custom-dialog-transition"
  >
    <div class="card-overlay" @click="close" />
    <v-card style="width: 560px">
      <v-card-title class="flex  align-center">
        <v-icon size="24" class="mr-4">$modalIcon</v-icon>
        <h3 class="font-weight-light">
          {{ $t('heading.instance.modal.previewSite.title') }}
        </h3>
        <v-btn class="ml-auto" icon x-small @click="close()">
          <v-icon size="24">$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p class="p-2 mb-0">{{ $t('heading.instance.modal.previewSite.message') }}</p>
        <template v-if="temporaryUrl">
          <div class="box d-flex justify-space-between align-center">
            <div class="d-flex align-center w-100">
              <span class="p-2">{{ $t('heading.instance.modal.previewSite.field') }}</span>
              <v-tooltip
                bottom
                transition="custom-tooltip"
                max-width="none"
                open-delay="150"
                close-delay="0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="temporary-url-wrapper"
                  >
                    <span class="p-2 info--text font-weight-600 ml-2 mr-2 temporary-url">{{ temporaryUrl }}</span>
                  </div>
                </template>
                <span class="p-2">{{ temporaryUrl }}</span>
              </v-tooltip>

              <copy-text-custom-button
                :text="temporaryUrlComputed"
                class="copy-button"
              >
                <v-btn
                  elevation="0"
                  color="success lighten-5 success--text"
                  class="ml-auto"
                >{{ $t('button.copyUrl') }}</v-btn>
              </copy-text-custom-button>
            </div>
          </div>
          <v-btn
              large
              elevation="0"
              color="primary"
              :href="temporaryUrlComputed"
              target="_blank"
              class="w-100 mb-8"
            >{{ $t('button.visitSite') }}</v-btn>
        </template>
      </v-card-text>
      <template v-if="!temporaryUrl">
      <v-card-actions class="d-flex flex-column px-8 pt-8">
          <v-btn
            large
            elevation="0"
            color="primary"
            block
            @click="getTemporaryUrl"
            :loading="submitting"
          >
            {{ $t('button.temporaryUrl.get') }}
          </v-btn>
        </v-card-actions>
        </template>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "../../apis/Api";
import CopyTextCustomButton from "../buttons/CopyTextCustomButton.vue";

export default {
  name: "PreviewSiteModal",
  components: {CopyTextCustomButton},
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    instance: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      step: 1,
      submitting: false,

      temporaryUrl: "",
    }
  },
  computed: {
    temporaryUrlComputed() {
      if (this.temporaryUrl.startsWith('https://')) {
        return this.temporaryUrl;
      }
      return 'https://' + this.temporaryUrl;
    }
  },
  mounted() {
    if (this.instance.temp_url) {
      this.temporaryUrl = this.instance.temp_url;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    getTemporaryUrl() {
      this.submitting = true;
      Api.post(`/instances/${this.instance.id}/preview`)
        .then(response => {
          this.temporaryUrl = response.data.data.temp_url;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  background: var(--v-gray-lighten6);
  color: var(--v-gray-darken1);
  border: 1px solid #CCCCCC;
  padding: 16px;
  text-align: center;
  border-radius: 8px;
  margin-block: 32px;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.temporary-url-wrapper {
  flex: 1;
  min-width: 0; // To jest kluczowe dla działania text-overflow
}

.temporary-url {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Dodaj tę klasę do przycisku "Kopiuj URL", jeśli potrzebujesz
.copy-button {
  flex-shrink: 0;
}
</style>