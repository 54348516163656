const ThemeModule = {
    state: {
        colors: {
            primary: {
                base: "#07C07E",
                lighten1: '#1FD895',
                lighten2: '#CDF2E5',
                lighten4: '#E6F9F2',
                darken1: '#0AAA71'
            },
        },
        style: {
            disableShadows: false
        },
        lightLogo: false,
        darkLogo: false,
        favicon: false,
    },
    mutations: {
        changeColorsTheme: function (state, payload) {
            state.colors.primary = payload.colors
            state.style = payload.style
            state.lightLogo = payload.logo
            state.darkLogo = payload.darkLogo
            state.favicon = payload.favicon
            state.name = payload.title
        }
    },
}

export default ThemeModule